@use "../../styles/global.scss" as *;

.skills {
    position: relative;
    height: 100vh;
    @include display-flex(column, center, center);
    &__wrappper {
        @include margin;
    }
    &__title {
        @include section-header;
        padding-bottom: 1rem;
        margin-bottom: 3rem;
        text-align: center;
    }
    &__grid-container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        max-width: 800px;
        gap: 2rem;
        margin: auto;
        @include tablet {
            grid-template-columns: repeat(5, 1fr);
        }
        
    }
    &__grid-item {
        text-align: center;
        &--logo {
            font-size: 3rem;
        }
        &--text {
            @include labels-buttons
        }
    }
}

.section-divider {
    height: 4rem;
}

