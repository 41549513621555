.switch {
    position: relative;
    display: inline-flex;
    width: 64px;
    height: 34px;
    &__icon {
        position: absolute;
        height: 24px;
        cursor: pointer;
    }
}
.switch input {
    opacity: 0;
}
.slider {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 34px;
    background-color: #454545;
    transition: ease-in 0.2s;
    &::before {
        position: absolute;
        content: "";
        z-index: 4;
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        border-radius: 50%;
        background-color: white;
        transition: ease-in 0.2s;

    }
}
input:checked + .slider::before {
    transform: translateX(30px);
}

input:checked + .slider {
    background-color: #ccc;
}
.sun {
    top: 5px;
    right: 5px;
}
.moon {
    top: 5px;
    left: 5px;
}