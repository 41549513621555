@forward "./partials/_fonts.scss";
@forward "./partials/_mixins.scss";
@forward "./partials/_reset.scss";
@forward "./partials/_variables";

@import url('https://fonts.googleapis.com/css?family=Lato:300,400|Poppins:300,400,800&display=swap');

body {
  // font-family: "Roboto Mono";
  font-family: "Poppins";
  overflow-x: hidden;
}

a {
  text-decoration: none;
  color: inherit;
}

html {
  scroll-behavior: smooth;
}


//--------------------//
//--animation styles--//
//--------------------//

.fade-in {
  opacity: 0;
  transition: 0.6s ease-in;
  
}
.fade-in.appear {
  opacity: 1;
}

.fade-in-left {
  opacity: 0;
  transform: translateX(-200px);
  transition: 0.8s ease-in;
  
}

.fade-in-left.appear {
  opacity: 1;
  transform: translateX(0);
}
.fade-in-right {
  opacity: 0;
  transform: translateX(200px);
  transition: 0.8s ease-in;
}

.fade-in-right.appear {
  opacity: 1;
  transform: translateX(0);
}