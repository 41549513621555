@font-face {
  font-family: "Roboto Mono";
  src: url("../../assests/fonts/Roboto_Mono/RobotoMono-Bold.eot");
  src: url("../../assests/fonts/Roboto_Mono/RobotoMono-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../../assests/fonts/Roboto_Mono/RobotoMono-Bold.woff2") format("woff2"),
    url("../../assests/fonts/Roboto_Mono/RobotoMono-Bold.woff") format("woff"),
    url("../../assests/fonts/Roboto_Mono/RobotoMono-Bold.ttf")
      format("truetype"),
    url("../../assests/fonts/Roboto_Mono/RobotoMono-Bold.svg#RobotoMono-Bold")
      format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto Mono";
  src: url("../../assests/fonts/Roboto_Mono/RobotoMono-Light.eot");
  src: url("../../assests/fonts/Roboto_Mono/RobotoMono-Light.eot?#iefix")
      format("embedded-opentype"),
    url("../../assests/fonts/Roboto_Mono/RobotoMono-Light.woff2")
      format("woff2"),
    url("../../assests/fonts/Roboto_Mono/RobotoMono-Light.woff") format("woff"),
    url("../../assests/fonts/Roboto_Mono/RobotoMono-Light.ttf")
      format("truetype"),
    url("../../assests/fonts/Roboto_Mono/RobotoMono-Light.svg#RobotoMono-Light")
      format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto Mono";
  src: url("../../assests/fonts/Roboto_Mono/RobotoMono-Medium.eot");
  src: url("../../assests/fonts/Roboto_Mono/RobotoMono-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../../assests/fonts/Roboto_Mono/RobotoMono-Medium.woff2")
      format("woff2"),
    url("../../assests/fonts/Roboto_Mono/RobotoMono-Medium.woff") format("woff"),
    url("../../assests/fonts/Roboto_Mono/RobotoMono-Medium.ttf")
      format("truetype"),
    url("../../assests/fonts/Roboto_Mono/RobotoMono-Medium.svg#RobotoMono-Medium")
      format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto Mono";
  src: url("../../assests/fonts/Roboto_Mono/RobotoMono-Regular.eot");
  src: url("../../assests/fonts/Roboto_Mono/RobotoMono-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../../assests/fonts/Roboto_Mono/RobotoMono-Regular.woff2")
      format("woff2"),
    url("../../assests/fonts/Roboto_Mono/RobotoMono-Regular.woff")
      format("woff"),
    url("../../assests/fonts/Roboto_Mono/RobotoMono-Regular.ttf")
      format("truetype"),
    url("../../assests/fonts/Roboto_Mono/RobotoMono-Regular.svg#RobotoMono-Regular")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto Mono";
  src: url("../../assests/fonts/Roboto_Mono/RobotoMono-SemiBold.eot");
  src: url("../../assests/fonts/Roboto_Mono/RobotoMono-SemiBold.eot?#iefix")
      format("embedded-opentype"),
    url("../../assests/fonts/Roboto_Mono/RobotoMono-SemiBold.woff2")
      format("woff2"),
    url("../../assests/fonts/Roboto_Mono/RobotoMono-SemiBold.woff")
      format("woff"),
    url("../../assests/fonts/Roboto_Mono/RobotoMono-SemiBold.ttf")
      format("truetype"),
    url("../../assests/fonts/Roboto_Mono/RobotoMono-SemiBold.svg#RobotoMono-SemiBold")
      format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto Mono";
  src: url("../../assests/fonts/Roboto_Mono/RobotoMono-Bold_1.eot");
  src: url("../../assests/fonts/Roboto_Mono/RobotoMono-Bold_1.eot?#iefix")
      format("embedded-opentype"),
    url("../../assests/fonts/Roboto_Mono/RobotoMono-Bold_1.woff2")
      format("woff2"),
    url("../../assests/fonts/Roboto_Mono/RobotoMono-Bold_1.woff") format("woff"),
    url("../../assests/fonts/Roboto_Mono/RobotoMono-Bold_1.ttf")
      format("truetype"),
    url("../../assests/fonts/Roboto_Mono/RobotoMono-Bold_1.svg#RobotoMono-Bold")
      format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto Mono";
  src: url("../../assests/fonts/Roboto_Mono/RobotoMono-Medium_1.eot");
  src: url("../../assests/fonts/Roboto_Mono/RobotoMono-Medium_1.eot?#iefix")
      format("embedded-opentype"),
    url("../../assests/fonts/Roboto_Mono/RobotoMono-Medium_1.woff2")
      format("woff2"),
    url("../../assests/fonts/Roboto_Mono/RobotoMono-Medium_1.woff")
      format("woff"),
    url("../../assests/fonts/Roboto_Mono/RobotoMono-Medium_1.ttf")
      format("truetype"),
    url("../../assests/fonts/Roboto_Mono/RobotoMono-Medium_1.svg#RobotoMono-Medium")
      format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto Mono";
  src: url("../../assests/fonts/Roboto_Mono/RobotoMono-Light_1.eot");
  src: url("../../assests/fonts/Roboto_Mono/RobotoMono-Light_1.eot?#iefix")
      format("embedded-opentype"),
    url("../../assests/fonts/Roboto_Mono/RobotoMono-Light_1.woff2")
      format("woff2"),
    url("../../assests/fonts/Roboto_Mono/RobotoMono-Light_1.woff")
      format("woff"),
    url("../../assests/fonts/Roboto_Mono/RobotoMono-Light_1.ttf")
      format("truetype"),
    url("../../assests/fonts/Roboto_Mono/RobotoMono-Light_1.svg#RobotoMono-Light")
      format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto Mono";
  src: url("../../assests/fonts/Roboto_Mono/RobotoMono-Regular_1.eot");
  src: url("../../assests/fonts/Roboto_Mono/RobotoMono-Regular_1.eot?#iefix")
      format("embedded-opentype"),
    url("../../assests/fonts/Roboto_Mono/RobotoMono-Regular_1.woff2")
      format("woff2"),
    url("../../assests/fonts/Roboto_Mono/RobotoMono-Regular_1.woff")
      format("woff"),
    url("../../assests/fonts/Roboto_Mono/RobotoMono-Regular_1.ttf")
      format("truetype"),
    url("../../assests/fonts/Roboto_Mono/RobotoMono-Regular_1.svg#RobotoMono-Regular")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
