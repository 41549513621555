$tablet-breakpoint: 768px;
$desktop-breakpoint: 1052px;

$primary-color-1-black: #1f201c;
$primary-color-1-black-opacity: #1f201cea;
$primary-color-2-white: #fafafa;
$secondary-color-1-dark-grey: #323232;
$color-icon: rgb(255, 179, 0);
$color-yellow: #e9d856;
$nav-background-mobile: rgba(255, 183, 1, 0.75);

//padding
$mobile-padding: 1rem;
$tablet-padding: 1.5rem;

$mobile-header-padding-top: 1.5rem;
$tablet-header-padding-top: 2.25rem;

//margin
$mobile-margin: 1rem;
$tablet-margin: 1.5rem;

$mobile-header-margin-top: 1.5rem;
$tablet-header-margin-top: 2.25rem;


// git test