.divider {
    text-align: center;
    position: absolute;
    width: 100%;
    bottom: 1.7rem;
    opacity: 0.1;
    transition: ease 0.1s;
    &:hover {
      opacity: 1;
    }
    &__arrow {
        font-size: 2rem;
        font-weight: 400;
        &:hover {
            cursor: pointer;
        }
        
    }
}
