@use "../../styles/global.scss" as *;

.navbar {
    width: 100%;
    transition: box-shadow .7s ease-in;
    background-color: inherit;
    z-index: 1000;
    &__wrapper {
        @include margin;
        position: relative;
        pointer-events: auto;
    }
    &__links {
        @include component-padding-top-bottom;
        @include tablet {
            @include display-flex(row, center, flex-end);
        }
        
        @media screen and (max-width: $tablet-breakpoint) {
            @include display-flex(column, flex-end, flex-start);
            background-color: $nav-background-mobile;
            height: 100vh;
            width: 30%;
            position: absolute;
            right: -1rem;
            z-index: 1;
            transform: translateX(100%);
            // opacity: 0;
            transition: transform 0.6s ease-in;
           
        }
        
    }
    &__link-item {
        margin-left: 1rem;
        padding: 0.5rem 0;
        position: relative;
        @media screen and (max-width: $tablet-breakpoint) {
            margin-right: 1rem;
            margin-top: 4rem;
        }

        &::before {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 0.2rem;
            border-radius: 0.5rem;
            background-color: $color-icon;
            transition: transform 300ms ease-in-out;
            transform: scaleX(0);
        }
        &:hover::before,
        :focus::before {
            transform: scaleX(1);
            font-weight: 600;
        }

    }
    &__switch-container {
        position: absolute;
        top: 1rem;
    }
}

.on-scroll {
    position: fixed;
}

.active {
    @include tablet {
    position: relative;
    margin-left: 1rem;
    padding: 0.5rem 0;
    transition: transform 0.3s ease-in;
        &::before {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 0.2rem;
            border-radius: 0.5rem;
            background-color: $color-icon;
            transform: scaleX(1);
        }    
    }
    
}

//hamburger menu styles and animation
.hamburger {
    position: absolute;
    right: 1rem;
    top: 1rem;
    cursor: pointer;
    z-index: 5;
    transition: all 0.4s ease;

    & span {
        display: block;
        width: 25px;
        height: 2px;
        background-color:black;
        margin: 5px;
    }
    @include tablet {
        display: none;
    }
}

@media screen and (max-width: $tablet-breakpoint ){
    body {
        overflow-x: hidden;
    }
    .appear {
        transform: translateX(0%);
        opacity: 1;
    }

    .rotate {
        transform: rotate(135deg);
    }
    .line1, .line3 {
        transition: all 0.4s ease;
    }
    .line1-rotate {
        transform: rotate(90deg) translate(7px, 0px);
    }
    .line3-rotate {
        transform: rotate(90deg) translate(-7px, 0px);
    }
}

@keyframes navLinkFade {
    from {
        // opacity: 0;
        transform: translateX(80px);
    }
    to {
        opacity: 1;
        transform: translateX(0px);
    }
    
}

