@use "../../styles/global.scss" as *;

.about {
    height: 100vh;
    position: relative;
    &__wrapper {
        @include margin;
        @include display-flex(column, center, center);
        height: 100%;
    }
    &__title {
        text-align: center;
        @include section-header;
        @include header-padding;
    }
    &__text {
        text-align: center;
        max-width: 600px;
        @include body-copy;
    }

    &__img-container {
        height: 9rem;
        width: 9rem;
        border-radius: 50%;
        overflow: hidden;
        order: 1;
        @include component-margin-top-bottom;
        @include tablet {
            height: 10rem;
            width: 10rem;
        }
    }

    &__img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        transform: scale(1.1);
    }
}

