

.skills__grid-item:nth-child(1) {
    transition-delay: 100ms;
}
.skills__grid-item:nth-child(2) {
    transition-delay: 150ms;
}
.skills__grid-item:nth-child(3) {
    transition-delay: 200ms;
}
.skills__grid-item:nth-child(4) {
    transition-delay: 250ms;
}
.skills__grid-item:nth-child(5) {
    transition-delay: 300ms;
}
.skills__grid-item:nth-child(6) {
    transition-delay: 350ms;
}
.skills__grid-item:nth-child(7) {
    transition-delay: 400ms;
}
.skills__grid-item:nth-child(8) {
    transition-delay: 450ms;
}
.skills__grid-item:nth-child(9) {
    transition-delay: 500ms;
}
.skills__grid-item:nth-child(10) {
    transition-delay: 550ms;
}
.skills__grid-item:nth-child(11) {
    transition-delay: 600ms;
}
.skills__grid-item:nth-child(12) {
    transition-delay: 650ms;
}
.skills__grid-item:nth-child(13) {
    transition-delay: 700ms;
}
.skills__grid-item:nth-child(14) {
    transition-delay: 750ms;
}
.skills__grid-item:nth-child(15) {
    transition-delay: 800ms;
}
