@use "../../styles/global.scss" as *;

.proj {
    &__container {
        margin: 0 auto;
        margin-top: 3rem;
        display: flex;
        flex-direction: column-reverse;
        @include tablet {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            row-gap: 3rem;
            justify-content: center;
        }

        &:nth-child(even) {
            flex-direction: column;
        }

        &--left {
            position: relative;
            @include display-flex(column, flex-start, center);            
            grid-column: 1 / 3;
            
        }
        &--right {
            position: relative;
            grid-column: 2 / -1;
            @include display-flex(column, flex-start, center);  
            @include tablet {
                align-items: flex-end;
                
            }
        }
    }
    &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
        transition: ease-in 0.2s;
        @include tablet {
            height: 100%;
        }
        @include desktop {
            filter: grayscale(100%); 
        }

        &--container {
            box-shadow: 0 12px 18px -6px rgba(0, 0, 0, 0.3);   
            border-radius: 10px; 
            width: 100%;
            height: 360px;
            overflow: hidden;
            @include tablet {
                height: 400px;
            }
        }

        

        &::before {
            content: "";
            position: absolute;
        }
        &:hover {
            filter: none;
            transform: scale(1.03);
            cursor: pointer;
        }
    }
    &__heading {
        @include subheader;
        @include header-padding;
        pointer-events: none;   
    }
    &__text {
        @include body-copy;
        padding: 1rem;
        border-radius: 10px;
        pointer-events: none; 
        @include tablet {
            max-width: 70%;
        }
    }
    &__tech-list {
        @include component-margin-top-bottom-small;
        &--item {
            font-family: 'Courier New', Courier, monospace;
            padding: 4px 12px;
            @include labels-buttons;
            
        }
    }
    &__icon {
        font-size: 1.5rem;
        margin: 0.25rem;
        pointer-events: auto;
        &:hover {
            pointer-events: all;
            color: $color-icon;
            cursor: pointer;
        }
        &--lock {
            font-size: 1.5rem;
            margin: 0.25rem;
            pointer-events: auto;
        }
    }

}
.proj__container--left, .proj__container--right {
    grid-row: 1 / 2; 
}

//align for project to switch between either the right or left side 
.right {
    overflow: hidden;
    z-index: 2; 
    pointer-events: none; 
    @include tablet {
        text-align: right;
    }
}
.left {
    overflow: hidden;
    z-index: 2;
    pointer-events: none;
    @include tablet {
        text-align: left;
    } 
}

//border for middle tech stack item
.middle {
    border-left: 1px solid black;
    border-right: 1px solid black;
}
